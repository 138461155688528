<script>
	import { getElementsArray, getElement } from '@Utils/Elements.js';
	import { getIconHtml, getIconUrl } from '@Utils/Utils.js';
	import { getContext, onMount } from 'svelte';
	import { deconstructHoleId } from '@Utils/DataController.js';
	import RenderModal from '@Views/RenderModal.svelte';
	import Popover from '@Components/Popover.svelte';
	import WikiModal from '@Views/WikiModal.svelte';
	import LoadingIndicator from '@Components/LoadingIndicator.svelte';
	import { unitsFactors } from '@turf/helpers';

	/**
	 * READ HERE BEFORE YOU CONTINUE!
	 *
	 * Uncomment the tabs to continue with that feature
	 * Change frame only (or onePerFrame) tools to _disabled: ... instead of disabled
	 *
	 */

	const { getEditorCanvas, getController, getGuide, isAdmin } = getContext('editor');
	const editorCanvas = getEditorCanvas();
	const guide = getGuide();
	const controller = getController();
	let dropdownMenuElement;
	let toolbarElement;
	let dropdownMenu = {
		icons: [
			{
				name: 'wc',
				icon: 'MenuWC',
				tool: 'icon',
				tooltip: 'WC',
				props: {
					menuIcon: 'MenuWC',
					iconName: 'WC',
					directionalIcon: 'DirectionalChevron'
				}
			},
			{
				name: 'nextTee',
				icon: 'MenuNextTee',
				tooltip: 'Next tee',
				tool: 'icon',
				props: {
					menuIcon: 'NextTee',
					iconName: 'NextTee',
					directionalIcon: 'DirectionalChevronRounded'
				}
			}
		]
	};
	function stopEditing() {
		editorCanvas.unfocus();
		editorCanvas.fitAllFrames();
	}
	let dropdownVisible = false;
	let modalvisible = false;
	
	let currentHoleId = null;
	let codes = controller.getCodes();
	let status = controller.status ?? {};
	let prompt = null;
	let selectedFrameData = null;
	let mapService = editorCanvas.currentMapService;
	let wikiPopover;
	let holeMenuGroups;
	let elements = getElementsArray().filter((element) => element.group !== 'Game');
	let _groups = {};
	for (let e of elements) {
		if (e.enabled === false) {
			continue;
		}
		if (_groups[e.group] === undefined) {
			_groups[e.group] = {
				name: e.group,
				items: []
			};
		}
		if (e.name === 'icon') {
			e.icon = localStorage.getItem('tool_icon_iconName') ?? 'MenuWC';
		}
		e.type = 'drawtool';
		e.id = e.name;
		e.disabled = true;
		_groups[e.group].items.push(e);
	}

	let groups = Object.values(_groups);
	let renderAndSave = false;
	$: renderAndSave = renderAndSave, stopEditing();
	$: groups;
	$: holeMenuGroups;
	$: isDarkMode = window.isDarkMode;
	$: currentTool = editorCanvas.currentTool;
	let miscItems = [
		{
			type: 'measure',
			id: 'measure',
			icon: 'Ruler',
			title: 'Measure',
			tooltip: 'Measure distance between two or more points'
		},
		{
			type: 'union',
			id: 'union',
			icon: 'Union',
			title: 'Combine',
			tooltip: 'Combine two elements of the same kind into a single element. The elements must be intersecting.'
		}
	];
	if (isAdmin) {
		miscItems.push({
			type: 'simplifyAllPolygons',
			id: 'simplifyAllPolygons',
			icon: 'Simplify',
			title: 'Simplify all polygons',
			tooltip: 'Simplify all polygons in the map. This might cause loss of detail in the polygons. This action cannot be undone'
		});
	}
	groups.push({
		name: 'Misc',
		items: miscItems
	});
	window.addEventListener('colorSchemeChanged', (e) => {
		isDarkMode = e.isDarkMode;
	});
	groups.push({
		name: 'Maps',
		items: [
			{
				image: getIconUrl('GoogleMapsLogo'),
				title: 'Goolge Maps',
				type: 'mapservice',
				id: 'google',
				tooltip: 'Google Maps',
				disabled: mapService === 'google',
				selected: mapService === 'google'
			},
			// {
			// 	image: getIconUrl('BingMapsLogo'),
			// 	title: 'Bing Maps',
			// 	type: 'mapservice',
			// 	id: 'bing',
			// 	tooltip: 'Bing Maps',
			// 	disabled: mapService === 'bing',
			// 	selected: mapService === 'bing'
			// },
			{
				image: getIconUrl('AzureMapsLogo'),
				title: 'Azure Maps',
				type: 'mapservice',
				id: 'azuremaps',
				tooltip: 'Azure Maps',
				disabled: mapService === 'azuremaps',
				selected: mapService === 'azuremaps'
			}
		]
	});
	groups.push({
		name: 'Status',
		items: [
			{
				type: 'status'
			}
		]
	});
	groups.push({
		name: 'Other',
		items: [
			{
				type: 'help',
				icon: 'Help',
				title: 'Help',
				tooltip: 'Help'
			},
			{
				type: 'save',
				icon: 'Save',
				title: 'Done',
				tooltip: 'Done'
			}
		]
	});
	function enableAllOfType(type) {
		for (let group of groups) {
			for (let item of group.items) {
				if (item.type === type && item.frameOnly === false) {
					item.disabled = false;
				}
			}
		}
		groups = [...groups];
	}
	function updateItem(id, props) {
		for (let group of groups) {
			for (let item of group.items) {
				if (item.id === id) {
					Object.assign(item, props);
				}
			}
		}
		if (holeMenuGroups) {
			for (let group of holeMenuGroups) {
				for (let item of group.items) {
					if (item.id === id) {
						Object.assign(item, props);
					}
				}
			}
			holeMenuGroups = [...holeMenuGroups];
		}
		groups = [...groups];
	}
	editorCanvas.on('mapServiceChanged', function (service) {
		updateItem('google', { disabled: service === 'google', selected: service === 'google' });
		updateItem('bing', { disabled: service === 'bing', selected: service === 'bing' });
		updateItem('azuremaps', { disabled: service === 'azuremaps', selected: service === 'azuremaps' });
		guide.done('chooseMap');
	});
	controller.on('fetchCompleted', function () {
		enableAllOfType('drawtool');
	});
	controller.on('alertsUpdated', function (holes) {
		if (!currentHoleId) {
			return;
		}
		updateHoleMenu(currentHoleId);
	});
	editorCanvas.on('currentToolChange', function (t, element) {
		updateItem(currentTool, { selected: false });
		updateItem(t, { selected: true });
		currentTool = t;
		let userTip = null;
		if (t) {
			if (element) {
				if (element.tooltype === 'Polygon' || element.tooltype === 'LineString') {
					prompt = {
						title: `Drawing: ${element.title}`,
						message: 'Hold shift for freehand drawing.'
					};
					userTip = {
						message: `User tip for ${element.title}`,
						details: ['Hold down shift and draw on freehand using the left mouse button. Release mouse button to finish the element.', 'Press ESC to deselect the tool.']
					};
				} else {
					prompt = {
						title: `Drawing: ${element.title}`
					};
					userTip = {
						message: `User tip for ${element.title}`,
						details: ['Press ESC to deselect the tool.']
					};
				}
			} else if (t == 'addFrame') {
				prompt = {
					title: 'Drawing: Hole outline'
				};
			} else if (t == 'measure') {
				prompt = {
					title: 'Measure',
					message: 'Click on the map to measure distance'
				};
				userTip = {
					message: `User tip when measuring distance`,
					details: ['Click on the map to measure distance, you can even click more than once.', 'Press ESC to deselect the tool.']
				};
			} else if (t == 'union') {
				prompt = {
					title: 'Combine',
					message: 'Click on two elements to combine them'
				};
				userTip = {
					message: `User tip when combining elements`,
					details: ['You can only combine elements that are intersecting and of the same kind.', 'Press ESC to cancel the tool.']
				};
			}
			if (userTip) {
				window.dispatchEvent(
					new CustomEvent('user-tip', {
						detail: {
							visibleFor: 10000,
							id: 'user-tip',
							message: userTip.message,
							details: userTip.details,
							action: {
								label: 'Stop showing tips',
								handler: () => {
									localStorage.setItem('hideUserTips', true);
								}
							}
						}
					})
				);
			}
		} else {
			prompt = null;
		}
	});
	editorCanvas.on('elementSelected', function (n, o) {
		if (n && n.feature.get('holeId')) {
			let el = n.getElement();
			if (el.onePerFrame) {
				updateItem(n.getElementName(), { selected: true });
			}
		}
		if (o && o.feature.get('holeId')) {
			updateItem(o.getElementName(), { selected: false });
		}
	});
	editorCanvas.on('measurementUpdated', function (measurement) {
		if (measurement) {
			prompt = {
				title: measurement.output.type,
				value: measurement.output.formatted
			};
		} else {
			prompt = null;
		}
	});

	controller.on('statusUpdated', function (s) {
		status = s;
	});
	function getColor(code) {
		if (code == codes.error) {
			return '#F9423A';
		}
		if (code == codes.idle) {
			return '#26D07C';
		}
		if (code == codes.hasUnsavedData) {
			return '#E48B23';
		}
		if (code == codes.savingToStorage) {
			return '#B1B5CE';
		}
		if (code == codes.loading) {
			return '#280071';
		}
		return '#280071';
	}
	function click(item) {
		if (item.feature) {
			editorCanvas.selectFeature(item.feature, true);
		} else if (item.type === 'drawtool') {
			if (item.name === 'icon') {
				editorCanvas.activateTool(item.name, {
					iconName: localStorage.getItem('tool_icon_iconName')
				});
			} else {
				editorCanvas.activateTool(item.name, item.props);
			}
		} else if (item.type === 'measure') {
			editorCanvas.activateTool('measure');
		} else if (item.type === 'mapservice') {
			editorCanvas.setMapService(item.id);
		} else if (item.type === 'help') {
			modalvisible = true;
		} else if (item.type === 'save') {
			renderAndSave = true;
		} else if (item.type === 'union') {
			editorCanvas.activateTool('union');
		} else if (item.type === 'simplifyAllPolygons') {
			if (confirm('Are you sure you want to simplify all polygons? This action cannot be undone.')) {
				editorCanvas.simplifyAllPolygons();
			}
		}
	}
	function getTooltip(group, item) {
		if (item.tooltip) {
			return item.tooltip;
		}
		return [group.name, ' - ', item.title].join('');
	}
	function updateHoleMenu(holeId) {
		if (!holeId) {
			for (let group of groups) {
				for (let item of group.items) {
					if (item.name === 'focus') {
						item.disabled = true;
					}
					if (item.name === 'green') {
						item.disabled = true;
					}
					if (item.name === 'tee') {
						item.disabled = true;
					}
				}
			}
			groups = [...groups];
			return;
		}
		let props = deconstructHoleId(holeId);
		let hole = controller.data.holes.find((h) => h.id == props.holeId);
		if (!hole) {
			return;
		}
		let arr = [];
		arr.push({
			name: 'Other',
			items: [
				{
					id: 'outline',
					name: 'outline',
					type: 'selection',
					icon: 'EditFrame',
					title: 'Hole outline',
					feature: selectedFrameData?.feature,
					tooltip: 'Select to edit hole outline',
					props: {
						holeId: holeId
					},
					selected: false
				},
				{
					id: 'focus',
					name: 'focus',
					type: 'drawtool',
					icon: 'Focus',
					title: 'Focus',
					tooltip: hole.mapInfo.hasFocus === true ? 'Select to edit focus' : 'Add Focus',
					props: {
						holeId: holeId
					},
					feature: selectedFrameData?.source.getFeatures().find((f) => f.get('elementName') === 'focus'),
					//disabled: hole.mapInfo.hasFocus === true,
					selected: false
				}
			]
		});
		arr.push({
			name: 'Green points',
			items: [
				{
					id: 'greenfront',
					name: 'greenfront',
					icon: 'GreenFront',
					title: 'Front green',
					tooltip: hole.mapInfo.green.front ? 'Select to edit Front green' : 'Add Front green',
					type: 'drawtool',
					props: {
						holeId: holeId
					},
					feature: selectedFrameData?.source.getFeatures().find((f) => f.get('elementName') === 'greenfront'),
					//disabled: hole.mapInfo.green.front === true,
					selected: false
				},
				{
					id: 'greencenter',
					name: 'greencenter',
					icon: 'GreenCenter',
					title: 'Center green',
					tooltip: hole.mapInfo.green.center ? 'Select to edit - Center green' : 'Add - Center green',
					type: 'drawtool',
					props: {
						holeId: holeId
					},
					feature: selectedFrameData?.source.getFeatures().find((f) => f.get('elementName') === 'greencenter'),
					//disabled: hole.mapInfo.green.center === true,
					selected: false
				},
				{
					id: 'greenback',
					name: 'greenback',
					icon: 'GreenBack',
					title: 'Back green',
					tooltip: hole.mapInfo.green.back ? 'Select to edit - Back green' : 'Add - Back green',
					type: 'drawtool',
					props: {
						holeId: holeId
					},
					feature: selectedFrameData?.source.getFeatures().find((f) => f.get('elementName') === 'greenback'),
					//disabled: hole.mapInfo.green.back === true,
					selected: false
				}
			]
		});

		let tees = controller.data.holeTees[props.holeId];
		arr.push({
			name: 'Tees',
			items: tees.map((tee) => {
				let el = getElement('tee' + tee.elementId);
				return {
					id: el.name,
					name: el.name,
					type: 'drawtool',
					icon: el.icon,
					title: tee.teeAlias,
					tooltip: hole.mapInfo.tee[tee.elementId] !== true ? `Add - ${tee.teeAlias} tee` : `Select to edit - ${tee.teeAlias} tee`,
					props: {
						holeId: holeId
					},
					feature: selectedFrameData?.source.getFeatures().find((f) => f.get('elementName') === 'tee' + tee.elementId),
					disabled: hole.mapInfo.tee[tee.elementId] === true,
					selected: false
				};
			})
		});
		holeMenuGroups = arr;

		for (let group of groups) {
			for (let item of group.items) {
				if (item.name === 'focus') {
					item.feature = selectedFrameData?.source.getFeatures().find((f) => f.get('elementName') === 'focus');
					item.disabled = hole.mapInfo.hasFocus === true;
				}
				if (item.name === 'green') {
					item.feature = selectedFrameData?.source.getFeatures().find((f) => f.get('elementName') === 'green');
					item.disabled = hole.mapInfo.hasGreen === true;
				}
				if (item.name === 'tee') {
					item.disabled = false;
				}
			}
		}
		groups = [...groups];
	}
	const onFrameFocused = (data) => {
		selectedFrameData = data;
		if (data) {
			currentHoleId = data.feature.get('holeId');
			updateHoleMenu(currentHoleId);
		} else {
			currentHoleId = null;
			holeMenuGroups = null;
			updateHoleMenu(null);
		}
	};
	function showDropdown(e, item) {
		if (item.dropdown) {
			dropdownMenuElement.innerHTML = '';
			let dd = dropdownMenu[item.dropdown];
			for (let i of dd) {
				let btn = document.createElement('button');
				btn.classList.add('dd-btn', 'p-1');
				btn.innerHTML = getIconHtml(i.icon);
				btn.addEventListener('click', () => {
					localStorage.setItem(`tool_${i.tool}_iconName`, i.icon);
					updateItem(item.id, { icon: i.icon });
					editorCanvas.activateTool(i.tool, i.props);
					hideDropdown();
				});
				dropdownMenuElement.appendChild(btn);
			}
			let ref = document.getElementById('dropdown-' + item.id);
			if (!ref) {
				return;
			}
			let rect = ref.getBoundingClientRect();
			dropdownMenuElement.style.left = rect.x + 'px';
			dropdownMenuElement.style.top = rect.y + rect.height + 'px';
			dropdownVisible = true;
			document.addEventListener('mousedown', onMouseDown);
		}
	}
	function hideDropdown() {
		document.removeEventListener('mousedown', onMouseDown);
		if (!dropdownMenuElement) {
			return;
		}
		dropdownVisible = false;
	}
	function toggleDropdown(e, item) {
		if (!dropdownVisible) {
			showDropdown(e, item);
		} else {
			hideDropdown();
		}
	}
	const onMouseDown = function (e) {
		if (!dropdownVisible) {
			return;
		}
		let t = e.target;
		while (t) {
			if (t.dataset.role === 'dropdownarrrow') {
				return;
			}
			if (t.id === 'dropdownmenu') {
				return;
			}
			t = t.parentElement;
		}
		hideDropdown();
	};
	onMount(() => {
		if (controller.initalFetchCompleted) {
			enableAllOfType('drawtool');
		}
		toolbarElement;
		let r = toolbarElement.getBoundingClientRect();
		editorCanvas.viewPadding[0] = r.height + r.top + 30;
	});

	editorCanvas.on('frameFocused', onFrameFocused);
</script>

<div bind:this={toolbarElement} class="toolbar">
	<div class="box box-shadow content flex flex-col gap-0">
		<!--div class="tabs" class:dark-tabs={isDarkMode}>
			<div class="tab tab-active">Basic</div>
			<<div class="tab">Premium</div>
			<div class="tab">Professional</div>
			<div class="tab" tooltip="Tools such as union, measure and more">Tools</div>>
			<div class="tab-spacer"></div>
			<div class="status-item">
				<button disabled={status.loading || status.code !== codes.hasUnsavedData} on:click={() => controller.save()} class="save-btn">
					<div class="status-message">{status.message}</div>
					<LoadingIndicator isLoading={status.loading} color={getColor(status.code)} />
				</button>
			</div>
		</div-->
		<div class="flex flex-row gap-0 menu-row">
			{#each groups as group}
				<div class="group flex flex-row items-center justify-center p-1" class:dark-group-border={isDarkMode}>
					{#each group.items as item}
						{#if item.type === 'status'}
							<div class="group flex flex-row justify-center p-1">
								<button disabled={status.loading || status.code !== codes.hasUnsavedData} on:click={() => controller.save()} class="save-btn">
									<div class="status-message">{status.message}</div>
									<LoadingIndicator isLoading={status.loading} color={getColor(status.code)} />
								</button>
							</div>
						{:else if item.image}
							<button class="map-service-btn max-h-9 flex-1 flex items-center justify-center" tooltip={getTooltip(group, item)} selected={item.selected} disabled={item.disabled} on:click={() => click(item)}>
								<img style="height: 22px" src={item.image} alt={item.title} />
							</button>
						{:else if dropdownMenu[item.dropdown]}
							<div id="dropdown-{item.id}" class="dd-container max-h-9 flex-1 flex items-center justify-center" selected={item.selected} disabled={item.disabled}>
								<button class="dd-btn dd-btn-left p-2" disabled={item.disabled} on:click={() => click(item)} tooltip={getTooltip(group, item)}>
									{@html getIconHtml(item.icon, isDarkMode ? '' : 'image-bordered')}
								</button>
								<button class="dd-btn dd-btn-right p-1 h-9 flex items-center justify-center" data-role="dropdownarrrow" disabled={item.disabled} on:click={(e) => toggleDropdown(e, item)}>{@html getIconHtml('DropdownChevronDown', 'dark:bg-white', 6, 4)}</button>
							</div>
						{:else}
							<button class="menu-btn p-2 max-h-9 flex-1 flex items-center justify-center" tooltip={getTooltip(group, item)} selected={item.selected} disabled={item.disabled} on:click={() => click(item)}>
								{@html getIconHtml(item.icon, 'dark:bg-white')}
							</button>
						{/if}
					{/each}
				</div>
			{/each}
		</div>

		<div class="flex flex-row gap-0 menu-row dark:bg-neutral-200/5 bg-neutral-200">
			{#if holeMenuGroups}
				{#each holeMenuGroups as group}
					<div class="flex flex-row items-center justify-center p-1">
						{#each group.items as item}
							{#if item.image}
								<button class="map-service-btn max-h-9 flex-1 flex items-center justify-center frame-info" class:frame-info-item-missing={!item.feature} class:frame-info-item-added={item.feature} tooltip={getTooltip(group, item)} selected={item.selected} disabled={item.disabled} on:click={() => click(item)}>
									<img style="height: 22px" src={item.image} alt={item.title} />
								</button>
							{:else}
								<button class="menu-btn p-2 max-h-9 flex-1 flex items-center justify-center frame-info" class:frame-info-item-missing={!item.feature} class:frame-info-item-added={item.feature} tooltip={getTooltip(group, item)} selected={item.selected} disabled={item.disabled} on:click={(e) => click(item)}>
									{@html getIconHtml(item.icon)}
								</button>
							{/if}
						{/each}
					</div>
				{/each}
			{/if}
			<div class="flex-1"></div>
			<div class="text-xs p-2 m-1 h-9 flex items-center justify-end dark:bg-neutral-200/10 bg-neutral-100 rounded-md" style="width: 250px">
				{#if prompt}
					<div class="flex flex-col gap-0 items-start justify-center p-1 flex-1">
						{#if prompt.title}
							<div>{prompt.title}</div>
						{/if}
						{#if prompt.message}
							<div class="italic">{prompt.message}</div>
						{/if}
					</div>
					{#if prompt.value}
						<div>{prompt.value}</div>
					{/if}
				{/if}
			</div>
		</div>
	</div>
</div>
<div bind:this={dropdownMenuElement} id="dropdownmenu" class="box-shadow content p-1 dropdown-menu flex flex-col gap-1" class:bg-neutral-200={!isDarkMode} class:box={isDarkMode} class:hidden={!dropdownVisible}></div>
<Popover behaviour="manual" bind:root={wikiPopover} id="helppopover" title="You can find the wiki here" anchor="helpbutton">
	<br />
	<button
		name="gotit"
		class="btn btn-sm btn-blue float-right"
		on:click={() => {
			guide.done('findWiki');
			if (wikiPopover.togglePopover) {
				wikiPopover.togglePopover(false);
			} else {
				wikiPopover.style.visibility = 'hidden';
			}
		}}>Got it!</button
	>
</Popover>
<WikiModal bind:visible={modalvisible} on:close={() => {}} />
{#if renderAndSave}
	<RenderModal bind:visible={renderAndSave} />
{/if}

<style lang="postcss">
	/* .tabs {
		font-size: 13px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		flex: 1;
	}
	.dark-tabs {
	}
	.tabs .tab {
		background-color: rgba(0, 0, 0, 0.2);
		padding: 8px 12px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	}
	.tabs .tab:first-child {
		padding: 8px 12px;
		border-left: none;
	}
	.tabs .tab-active {
		border-left: 1px solid rgba(255, 255, 255, 0.1);
		border-right: 1px solid rgba(255, 255, 255, 0.1);
		background-color: transparent;
		border-bottom: none;
	}

	.tabs .tab-spacer {
		content: ' ';
		display: block;
		flex: 1;
		align-self: stretch;
		background-color: rgba(0, 0, 0, 0.2);
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	}
	.tabs .status-item {
		align-self: stretch;
		background-color: rgba(0, 0, 0, 0.2);
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		padding: 2px;
	}
	.tabs .status-item button {
		height: 100%;
	}
	.tabs .tab-disabled {
		color: rgba(255, 255, 255, 0.2);
		background-color: rgba(0, 0, 0, 0.1);
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	} */
	.frame-info {
		position: relative;
	}
	.frame-info:after {
		position: absolute;
		bottom: -7px;
		content: '';
		width: 6px;
		height: 6px;
		border-radius: 3px 3px;
	}
	.frame-info-item-missing::after {
		background-color: rgba(194, 58, 58, 0.8);
	}
	.frame-info-item-added::after {
		/*background-color: rgba(58, 194, 58, 0.8);*/
	}
	.dropdown-menu {
		border-radius: 10px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 100;
	}
	.save-btn {
		display: flex;
		padding-left: 7px;
		padding-right: 7px;
		flex-direction: row;

		gap: 6px;
		align-items: center;
		border-radius: 8px;
	}
	.save-btn:disabled {
		opacity: 0.5;
	}
	.save-btn:hover:not([disabled]) {
		outline: theme('colors.blue.100') solid 2px;
	}
	.save-btn:focus-visible:not([disabled]) {
		background: rgba(226, 243, 255, 1);
		outline: theme('colors.blue.500') solid 2px;
	}
	.save-btn:active:not([disabled]) {
		background: theme('colors.blue.100');
		outline: theme('colors.blue.500') solid 2px;
	}
	.toolbar .content {
		border-radius: 8px 8px 8px 8px;
		pointer-events: all;
		overflow: hidden;
	}
	.menu-row {
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	}
	.menu-row:last-child {
		border-bottom: none;
	}
	.toolbar {
		pointer-events: none;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		z-index: 50;
		position: absolute;
		top: 10px;
		left: 0;
		right: 0;
	}
	.group {
		border-left: 1px solid rgba(0, 0, 0, 0.1);
	}
	.dark-group-border {
		border-left: 1px solid rgba(255, 255, 255, 0.1);
	}
	.group:first-child {
		margin-left: 10;
		border-left: none;
	}
	.status-message {
		font-size: 14px;
		width: 70px;
		padding-right: 4px;
		text-align: right;
	}
	.save-btn {
		border-radius: 8px;
	}
	.save-btn:disabled {
		opacity: 0.5;
	}
	.save-btn:hover:not([disabled]) {
		outline: theme('colors.blue.100') solid 2px;
	}
	.save-btn:focus-visible:not([disabled]) {
		background: rgba(226, 243, 255, 1);
		outline: theme('colors.blue.500') solid 2px;
	}
	.save-btn:active:not([disabled]) {
		background: theme('colors.blue.100');
		outline: theme('colors.blue.500') solid 2px;
	}
	.map-service-btn {
		opacity: 0.3;
		filter: saturate(0.5);
		margin-left: 5px;
		margin-right: 5px;
	}
	.map-service-btn * {
		pointer-events: none;
	}
	.map-service-btn:disabled {
		opacity: 0.5;
	}
	.map-service-btn:hover:not([disabled]) {
		opacity: 1;
		filter: saturate(1);
	}
	.map-service-btn:focus-visible:not([disabled]) {
		opacity: 1;
		filter: saturate(1);
	}
	.map-service-btn:active:not([disabled]) {
		opacity: 1;
		filter: saturate(1);
	}
	.map-service-btn[selected='true'] {
		opacity: 1;
		filter: saturate(1);
	}
</style>
