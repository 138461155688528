<script>
	import { onMount, setContext } from 'svelte';
	import FrameElementExplorer from '@Views/FrameElementExplorer.svelte';
	import Preview from '@Views/Preview.svelte';
	import Toolbar from './Toolbar.svelte';
	import Guide from '@Views/Guide/Guide.svelte';
	import Holebar from '@Views/Holebar.svelte';
	import OptionsPanel from '@Views/OptionsPanel.svelte';
	import Messages from '@Views/Messages.svelte';
	import { guiDimensionsStore } from '@Stores/stores.js';
	export let isAdmin;
	export let isDev;
	export let canvas;
	export let controller;
	export let config;
	export let guideController;
	export let colorSchemeManager;
	$: unauthorized = controller.unauthorized;
	$: fatalError = false
	setContext('editor', {
		isAdmin: isAdmin,
		isDev: isDev,
		getEditorCanvas: () => canvas,
		getConfig: () => config,
		getController: () => controller,
		getGuide: () => guideController,
		getColorSchemeManager: () => colorSchemeManager
	});
	let codes = controller.getCodes();
	controller.on('statusUpdated', function (s) {
		if(s.code === codes.error) {
			fatalError = true
		} else {
			fatalError = false
		}
	});
	controller.on('authorizationStatus', (s) => {
		unauthorized = s;
	});
</script>

<div class="gui" bind:clientWidth={$guiDimensionsStore.w} bind:clientHeight={$guiDimensionsStore.h}>
	
	{#if unauthorized}
		<div style="position:absolute; z-index: 2;" class="flex justify-center items-center w-full h-full">
			<div style="width: 300px;">
				<h1 class="text-lg">Unauthorized</h1>
				<p class="text-sm">You are not authorized to access this page. Please return to golfoffice to continue</p>
				<br />
				<button
					class="btn btn-sm btn-solid-blue"
					on:click={() => {
						window.parent.postMessage(
							{
								action: 'closeMap'
							},
							config.golfOfficeFrontendURL
						);
					}}>Back to golfoffice</button
				>
			</div>
		</div>
	{:else if fatalError}
		<div style="position:absolute; z-index: 2;" class="flex justify-center items-center w-full h-full">
			<div style="width: 300px;">
				<h1 class="text-lg">Fatal error</h1>
				<p class="text-sm">An error occurred while loading the editor. Try realoading the page. If the error perists please contact customer support!</p>
				<br />
				<button
				class="btn btn-sm btn-solid-blue"
				on:click={() => {
					window.location.reload();
				}}>Reload</button
			>
				<button
					class="btn btn-sm btn-solid-white"
					on:click={() => {
						window.parent.postMessage(
							{
								action: 'closeMap'
							},
							config.golfOfficeFrontendURL
						);
					}}>Back to golfoffice</button
				>
			</div>
		</div>
	{:else}
		<Toolbar />
		<OptionsPanel />
		<FrameElementExplorer />
		<Preview />
		<Holebar />
		<Messages />
		<Guide controller={guideController} />
	{/if}
</div>

<style lang="postcss">
	.gui {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		display: flex;
	}
</style>
